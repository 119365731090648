@mixin positioning {
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 100%;
}

.wheel {
  --z-index-frame: 1;
  --z-index-segments: 2;
  --z-index-segments-border: 2;
  --z-index-blurring: 2;
  --z-index-blackout: 2;
  --z-index-diamond: 3;
  --z-index-button: 4;
  --rotating-area-size: 88%;
  --c-svg-star: var(--c-light-sand);
  --blackout-color: rgb(11, 3, 34);

  position: relative;
  margin: auto;
  width: clamp(340px, 92vmin, 720px);
  height: clamp(340px, 92vmin, 720px);

  &::after {
    @include positioning;

    z-index: var(--z-index-segments-border);
    width: var(--rotating-area-size);
    height: var(--rotating-area-size);
    content: '';
    box-shadow: 0 0 0 4px inset var(--c-deep-purple-blue);
    pointer-events: none;
  }

  &::before {
    @include positioning;

    z-index: var(--z-index-diamond);
    pointer-events: none;
    bottom: auto;
    content: '';
    width: max(2.8vmax, 40px);
    aspect-ratio: 14 / 16;
    border-radius: unset;
    background:
      url(https://mega-wheel-ru-020424.pages.dev/wheel-diamond.2606266a.png)
      center / cover
      no-repeat;
    filter: drop-shadow(0 0.6vmin 0.3vmin rgba(0, 0, 0, 0.7));
  }

  &__frame {
    @include positioning;

    z-index: var(--z-index-frame);
    width: 100%;
    height: 100%;

    .image-container--wheel-frame {
      position: relative;
      overflow: hidden;
      border-radius: 100%;
    }
  }

  &__decor {
    @include positioning;
    pointer-events: none;

    canvas {
      @include positioning;

      z-index: 1;
      width: 100%;
      height: 100%;
    }

    &::after {
      @include positioning;

      width: var(--rotating-area-size);
      height: var(--rotating-area-size);
      z-index: var(--z-index-blurring);
      content: '';
      transform: translate3d(0, 0, 0);
      backdrop-filter: blur(var(--blurring));
      will-change: transform;
      backface-visibility: hidden;
      pointer-events: none;
    }

    &::before {
      --cutout: calc(100% - (100% / var(--blackout-angle, 8)));
      --color: var(--blackout-color);

      opacity: var(--blackout-opacity, 0);

      @include positioning;

      width: var(--rotating-area-size);
      height: var(--rotating-area-size);
      z-index: var(--z-index-blackout);
      content: '';
      background: conic-gradient(var(--color) var(--cutout), transparent var(--cutout));
      transform: rotate(calc((360deg / var(--blackout-angle, 8)) / 2));
      pointer-events: none;
    }
  }

  &__segments {
    @include positioning;

    z-index: var(--z-index-segments);
    overflow: hidden;
    will-change: transform;
    pointer-events: none;
    background-image: url(https://mega-wheel-ru-020424.pages.dev/wheel-segments-900.d74b60bd.jpg);
    background-position: center;
    background-size: contain;
    width: var(--rotating-area-size);
    height: var(--rotating-area-size);
  }

  &__button {
    @include positioning;

    z-index: var(--z-index-button);
    width: min(30vmin, 160px);
    height: min(30vmin, 160px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    background-color: #121212;

    .is-spinning &,
    .end-last-spin & {
      pointer-events: none;
      animation: none;

      .svg-icon--cartwheel {
        animation: none;
        opacity: 0.25;
      }
    }

    body.first-spin-complete & {

    }
  }
}
