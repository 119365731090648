:root {
  // z-orders
  --z-index-particles: 1;
  --z-index-lasers: 3;
  --z-index-decor-page: 4;
  --z-index-page: 10;
  --z-index-womans: 12;
  --z-index-modal: 100;

  // colors
  --c-white: rgb(245, 245, 245);
  --c-black: rgb(1, 0, 15);
  --c-deep-purple-blue: rgb(31, 13, 64);

  //
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));

  @media (width >= 992px) {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
  }

  // offsets
  --v-offset-sm: max(4vmin, 20px);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  // fonts
  --min-fs: 0.6;
  --max-fs: 1;
  --min-vw: 60;
  --max-vw: 160;

  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // scrollbar
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}
