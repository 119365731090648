:root {
  --max-fs: 1;
  --min-fs: .75;
  --max-vw: 120;
  --min-vw: 80;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc(var(--min-vw) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / (var(--max-vw)  - var(--min-vw)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-default, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  font-size: var(--font-size-clamp);
  line-height: var(--default-line-height);
  font-weight: 400;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --z-index-particles: 1;
  --z-index-lasers: 3;
  --z-index-decor-page: 4;
  --z-index-page: 10;
  --z-index-womans: 12;
  --z-index-modal: 100;
  --c-white: #f5f5f5;
  --c-black: #01000f;
  --c-deep-purple-blue: #1f0d40;
  --app-gap: 20px;
  --app-wadth-min: 300px;
  --app-wadth-max: 1440px;
  --app-wadth: clamp(var(--app-wadth-min), calc(100vw - var(--app-gap)), var(--app-wadth-max));
  --v-offset-sm: max(4vmin, 20px);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);
  --min-fs: .6;
  --max-fs: 1;
  --min-vw: 60;
  --max-vw: 160;
  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
  --c-scrollbar-track: var(--c-bg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}

@media (width >= 992px) {
  :root {
    --app-wadth: clamp(var(--app-wadth-min), calc(100vw - (var(--app-gap) * 2)), var(--app-wadth-max));
  }
}

html, body {
  position: relative;
}

body {
  background-color: var(--c-black);
  width: 100vw;
  min-height: 100dvh;
  overflow: hidden auto;
}

.page {
  z-index: 2;
  gap: var(--v-offset-sm) 0;
  grid-template: auto / auto;
  min-height: 100dvh;
  display: grid;
  position: relative;
  overflow: hidden;
}

.page__logotype {
  z-index: 10;
  padding-top: calc(var(--v-offset-sm) / 2);
  place-self: center;
  position: relative;
}

.page__gain {
  z-index: 10;
  place-self: center;
  position: relative;
}

.page__wheel {
  aspect-ratio: 1;
  width: fit-content;
  margin: auto;
  position: relative;
}

.page__payment {
  z-index: 10;
  padding-bottom: calc(var(--v-offset-sm) / 2);
  place-self: center;
  position: relative;
}

#iconset {
  top: -9999px;
}

.image-container {
  width: 100%;
  height: 100%;
}

.image-container picture {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-container img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.wheel {
  --z-index-frame: 1;
  --z-index-segments: 2;
  --z-index-segments-border: 2;
  --z-index-blurring: 2;
  --z-index-blackout: 2;
  --z-index-diamond: 3;
  --z-index-button: 4;
  --rotating-area-size: 88%;
  --c-svg-star: var(--c-light-sand);
  --blackout-color: #0b0322;
  width: clamp(340px, 92vmin, 720px);
  height: clamp(340px, 92vmin, 720px);
  margin: auto;
  position: relative;
}

.wheel:after {
  z-index: var(--z-index-segments-border);
  width: var(--rotating-area-size);
  height: var(--rotating-area-size);
  content: "";
  box-shadow: 0 0 0 4px inset var(--c-deep-purple-blue);
  pointer-events: none;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.wheel:before {
  z-index: var(--z-index-diamond);
  pointer-events: none;
  content: "";
  aspect-ratio: 14 / 16;
  border-radius: 100%;
  border-radius: unset;
  filter: drop-shadow(0 .6vmin .3vmin #000000b3);
  background: url("https://mega-wheel-ru-020424.pages.dev/wheel-diamond.2606266a.png") center / cover no-repeat;
  width: max(2.8vmax, 40px);
  margin: auto;
  position: absolute;
  inset: 0 0 auto;
}

.wheel__frame {
  z-index: var(--z-index-frame);
  border-radius: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.wheel__frame .image-container--wheel-frame {
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.wheel__decor {
  pointer-events: none;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.wheel__decor canvas {
  z-index: 1;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.wheel__decor:after {
  width: var(--rotating-area-size);
  height: var(--rotating-area-size);
  z-index: var(--z-index-blurring);
  content: "";
  backdrop-filter: blur(var(--blurring));
  will-change: transform;
  backface-visibility: hidden;
  pointer-events: none;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  transform: translate3d(0, 0, 0);
}

.wheel__decor:before {
  --cutout: calc(100% - (100% / var(--blackout-angle, 8)));
  --color: var(--blackout-color);
  opacity: var(--blackout-opacity, 0);
  width: var(--rotating-area-size);
  height: var(--rotating-area-size);
  z-index: var(--z-index-blackout);
  content: "";
  background: conic-gradient(var(--color) var(--cutout), transparent var(--cutout));
  transform: rotate(calc(360deg / var(--blackout-angle, 8) / 2));
  pointer-events: none;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.wheel__segments {
  z-index: var(--z-index-segments);
  will-change: transform;
  pointer-events: none;
  width: var(--rotating-area-size);
  height: var(--rotating-area-size);
  background-image: url("https://mega-wheel-ru-020424.pages.dev/wheel-segments-900.d74b60bd.jpg");
  background-position: center;
  background-size: contain;
  border-radius: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.wheel__button {
  z-index: var(--z-index-button);
  cursor: pointer;
  background-color: #121212;
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min(30vmin, 160px);
  height: min(30vmin, 160px);
  margin: auto;
  display: flex;
  position: absolute;
  inset: 0;
}

.is-spinning .wheel__button, .end-last-spin .wheel__button {
  pointer-events: none;
  animation: none;
}

.is-spinning .wheel__button .svg-icon--cartwheel, .end-last-spin .wheel__button .svg-icon--cartwheel {
  opacity: .25;
  animation: none;
}
/*# sourceMappingURL=index.1467a728.css.map */
